<template>
  <v-main>
    <v-container fluid>

      <div v-if="spamComponents[0].isVisible">
        <send-spam :is-only-one-direct-spam="false"></send-spam>
      </div>
      <div v-if="spamComponents[1].isVisible">
       <tabs-by-bots-server :is-history-displayed="true"></tabs-by-bots-server>
      </div>
      <div v-if="spamComponents[2].isVisible">
       <tabs-by-bots-server></tabs-by-bots-server>
      </div>




    </v-container>
    <v-bottom-navigation
        value="1"
        color="teal"
        app
        grow
        shift
    >
      <v-btn
          v-for="(route, key) in routes"
          @click="togleComponents(route.to)"
          :key="'route' + key"
          min-width="48"
          class="px-0"

      >
        <span v-if="$vuetify.breakpoint.smAndUp">{{ route.name }} </span>
        <v-icon>{{ route.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-main>
</template>

<script>

import SendSpam from "@/components/Spam/SendSpam.vue";
import TabsByBotsServer from "@/components/Spam/TabsByBotsServer.vue";


export default {
  name: "SpammerPage",
  components: {
    SendSpam,
    TabsByBotsServer
  },


  data: () => ({

    tmp: false,
    device: {},
    fab: {
      isOpen: false,
      direction: 'top',
      absolute: true,
      fixed: true,
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
    },
    changeDeviceDialog: false,
    createNewDevice: false,
    createNewVenue: false,
    createNewCashier: false,
    updApk: false,
    apkData: {aisino: {version: ' n/a'}, other : {version: ' n/a'}},
    spamComponents: [
      {name: 'sendNew', isVisible: true},
      {name: 'sendHistory', isVisible: false},
      {name: 'users', isVisible: false},
    ],
    availableData: {
      avalibleLocations: [],
      avalibleCashiers: [],
    },

    routes: [
      {icon: 'mdi-message-arrow-right', name: 'Розсилка', to: 'sendNew'},
      {icon: 'mdi-message-text-clock', name: 'Історія Розсилок', to: 'sendHistory'},
      {icon: 'mdi-account-group', name: 'Клієнти', to: 'users'},
    ],
  }),

  mounted() {
  },
  methods: {

    togleComponents(name) {
      this.tmp = !this.tmp;
      this.spamComponents.forEach(component => {
        if (name == component.name) {
          component.isVisible = true;
        } else {
          component.isVisible = false;
        }
      })
    },
  },
}
</script>

<style scoped>

.add-btn {
  transition: transform .2s;
}

.add-btn:hover {
  transform: scale(1.5) rotate(180deg);
}

.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}

</style>
