<template>
  <v-container fluid>
    <v-card v-if="historyData">
      <v-card-title>
        {{historyData.length}} записів
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Пошук"
            single-line
            hide-details
            clearable
        ></v-text-field>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="historyData"
          :search="search"
          item-key="_id"
          show-expand
      >

        <template v-slot:expanded-item="{headers, item }">
          <td :colspan="headers.length">

            <v-item-group>
              <v-container>
                <v-row>
                  <v-col cols="10" md="4">
                    <v-item>
                      <v-card>
                        <v-card-subtitle>
                          Текст:
                        </v-card-subtitle>
                        <v-card-text>
                          {{ item.text}}
                        </v-card-text>
                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col cols="10" md="4">
                    <v-item>
                      <v-card>
                        <v-card-subtitle>
                          Telegram: <b>{{((item.telegram / item.total) * 100).toFixed(2)}}%</b> <small>(юзерів)</small>
                        </v-card-subtitle>
                        <v-card-text>
                         Відправлено: <b>{{ item.telegram}}</b>
                          <br>
                          Успішно: <b>{{ 'success' in item ? item.success.telegram : 'N/A'}} {{ 'success' in item ? `(${ ((item.success.telegram / item.telegram) * 100).toFixed(2)}%)`: '' }} </b>
                          Не успішно: <b>{{ 'success' in item ? item.unsuccess.telegram : 'N/A'}}</b>
                        </v-card-text>
                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col cols="10" md="4">
                    <v-item>
                      <v-card>
                        <v-card-subtitle>
                          Viber: <b>{{((item.viber / item.total) * 100).toFixed(2)}}%</b> <small>(юзерів)</small>
                        </v-card-subtitle>
                        <v-card-text>
                          Відправлено: <b>{{ item.viber}}</b>
                          <br>
                          Успішно: <b>{{ 'success' in item ? item.success.viber : 'N/A'}} {{ 'success' in item ? `(${ ((item.success.viber / item.viber) * 100).toFixed(2)}%)`: '' }} </b>
                          Не успішно: <b>{{ 'success' in item ? item.unsuccess.viber : 'N/A'}}</b>
                        </v-card-text>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-container>
            </v-item-group>

          </td>
        </template>

        <template v-slot:[`item.text`]="{ item }">
          {{item.text.substring(0,10) + '...'}}
        </template>

        <template v-slot:[`item.total`]="{ item }">
          {{item.total}} {{ 'success' in item ? `(${ (((item.success.telegram + item.success.viber) / item.total ) * 100).toFixed(1) }% успішно)` : '' }}
        </template>

        <template v-slot:[`item.success.telegram`]="{ item }">
          {{ 'success' in item ? `${item.success.telegram} (${ ((item.success.telegram / item.telegram ) * 100).toFixed(1) }%)` : '' }}
        </template>

        <template v-slot:[`item.success.viber`]="{ item }">
          {{ 'success' in item ? `${item.success.viber} (${ ((item.success.viber / item.viber ) * 100).toFixed(1) }%)` : '' }}
        </template>

        <template v-slot:[`item.imgUrl`]="{ item }">
          <a v-if="item.imgUrl" :href="item.imgUrl" target="_blank">
            <v-img height="36" width="36"  :src="item.imgUrl"></v-img>
          </a>
          <v-icon v-else large>mdi-image-off-outline</v-icon>
        </template>

      </v-data-table>
    </v-card>



    <div v-else>
      <v-progress-linear
          indeterminate
      ></v-progress-linear>
      <v-skeleton-loader
          type="table-row-divider@6"
      ></v-skeleton-loader>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/apiServices/apiService";

export default {
  name: "SpamHistory",
  props: {
    spamBotData: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    search: '',
    headers: [
      { text: 'Початок', value: 'createOn', align: 'center' },
      { text: 'Завершення', value: 'statusHistory[0].modifiedOn', align: 'center' },
      { text: 'Текст', value: 'text', align: 'center' },
      { text: 'Разом', value: 'total', align: 'center' },
      { text: 'Telegram', value: 'telegram' , align: 'center'},
      { text: 'Viber', value: 'viber', align: 'center' },
      { text: 'Успішно (Telegram)', value: 'success.telegram', align: 'center'},
      { text: 'Успішно (Viber)', value: 'success.viber', align: 'center' },
      { text: 'Картинка', value: 'imgUrl'},
    ],
    historyData: null,

  }),
  methods: {
    async getSpamHistory(){
      try {
        const historyResponse = await apiService.getSpamHistory({
          spammerServiceUrl:this.spamBotData.server,
          botServerQuery: this.spamBotData.server});
        if(historyResponse.success){
          if(historyResponse.result.length >= 1){
            console.log(historyResponse.result)
            this.historyData = historyResponse.result;
          }else{
            console.log('No spam histories records')
          }
        }else{
          alert(`${historyResponse.error}`)
        }
      }catch (e) {
        alert(`${e}`)
      }
    },
  },

  async mounted () {
    console.log('Spam History Mounted: ', this.spamBotData)
    await this.getSpamHistory();
  },


}
</script>

<style scoped>

</style>
