const isDevProd = true


const URLs = {
    baseURL: process.env.NODE_ENV === 'production' ? '/api' : (isDevProd ? 'https://royalcasino.ua/api' : 'https://dev.royalcasino.ua/api'),
    // baseURL: 'https://royalcasino.ua/api',
    // baseURL: 'https://dev.royalcasino.ua/api',
    spammerServiceUrls: [
        {
            name: 'RoyalClub Lviv',
            color: 'primary',
            server: 'https://bt.royalcasino.ua',
        },
        {
            name: 'RoyalClub Чернівці',
            color: 'success',
            server:'https://bt2.royalcasino.ua',
        },
        // {
        //     name: 'RoyalWin',
        //     color: 'orange darken-3',
        //     server: null,
        // }
    ]
    // spammerServiceUrl: 'https://3ad5-195-246-217-253.ngrok-free.app'


}

console.log('[i] baseURL:', URLs.baseURL)
export default URLs;
