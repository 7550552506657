<template>

  <v-container fluid>
    <v-col v-if="isErrorGetData.state" md="12" class="pa-9" style="text-align: center">
      <h1>{{ isErrorGetData.msg }}</h1>
      <br>
      <h3>{{ isErrorGetData.errMsg }}</h3>
    </v-col>
    <v-progress-linear v-if="loading"
                       :active="loading"
                       :indeterminate="loading"
                       height="6px"
    ></v-progress-linear>
    <v-row v-if="loading">
      <v-col
          style="margin-top: 20px"
          v-for="index in 8"
          :key="index"
          cols="6"
          md="4">
        <v-skeleton-loader
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div v-if="!isErrorGetData.state && !loading">
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-tabs v-model="filterTab" @change="filterChanged">
                <v-tab v-for="(tabItem, tabIndex) in ['Усі', 'Моб Касири', 'Poster' ,' Кіоски']" :key="tabIndex">
                  {{ tabItem }}
                  <small style="position:absolute; top: 0.8%; right: 0.5%" v-if="filterTab === tabIndex">
                    {{ filteredDevices.length }}</small>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-text-field clearable v-model="searchQuery" label="Пошук" prepend-icon="mdi-magnify"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="auto" v-if="filterTab === 3">
          <expansion-actions-wrapper-component></expansion-actions-wrapper-component>
        </v-col>
        <span v-if="filterTab !== 1" style="padding-left: 1%">
        <v-switch
            v-model="isVirtualVisible"
            label="Показати віртуальні"
        ></v-switch>
      </span>
      </v-row>
    </div>

    <v-row>
      <v-col style="margin-top: 20px"
             v-for="(device) in filteredDevices"
             :key="device.deviceId"
             cols="6"
             md="4"
      >
        <v-card
            class="pa-2"
            tile
            elevation="9"
        >
          <v-app-bar
              flat

          >
            <!--            <v-app-bar-nav-icon></v-app-bar-nav-icon>-->
            <!--            <v-toolbar-title class="text-h6  pl-0">-->
            <v-toolbar-title class="text-h5 mb-1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">

                  <v-avatar
                      v-if="String(device.type).toUpperCase() === 'KIOSK' || String(device.type).toUpperCase() === 'POSTER'"
                      tile
                      size="25"
                  >

                    <img v-if="String(device.type).toUpperCase() === 'KIOSK'"
                         :src="require(`../assets/kiosk_${device.isActive ? 'active' : 'noactive'}.png`)"
                         alt="kiosk"

                    >

                    <img v-else
                         :src="require(`../assets/pos_${device.isActive ? 'active' : 'noactive'}.png`)"
                         alt="poster"
                    >
                  </v-avatar>
                  <v-icon v-else
                          :color="device.isActive ? 'success' : 'pink'"
                          dark
                          v-bind="attrs"
                          v-on="on"
                  >
                    mdi-cellphone
                  </v-icon>

                </template>
                <span>{{ device.isActive ? 'Активний' : 'Вимкнутий' }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                            <span
                                v-bind="attrs"
                                v-on="on">
                              ID: {{ device.deviceId }}
                            </span>
                </template>
                <span>{{ device.comment }}</span>
              </v-tooltip>
              <copy-to-clipboard-component hint-text="id" :value="device.deviceId"></copy-to-clipboard-component>
              <span v-if="String(device.type).toUpperCase() === 'KIOSK'">
                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="openSSH(device.ipAddress)">
                    <v-icon size="20" color="primary">mdi-console</v-icon>
                  </v-btn>
                </template>
                <span>SSH</span>
              </v-tooltip>
              </span>
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <device-quick-action :device="device"></device-quick-action>
            <!--            <v-btn-->
            <!--                color="red"-->
            <!--                icon-->
            <!--            >-->
            <!--              <v-icon>mdi-dots-vertical</v-icon>-->
            <!--            </v-btn>-->
          </v-app-bar>
          <!--          <v-list-item>-->
          <!--            <v-list-item-title class="text-h5 mb-1">-->
          <!--              <v-tooltip top>-->
          <!--                <template v-slot:activator="{ on, attrs }">-->

          <!--                  <v-avatar v-if="String(device.type).toUpperCase() === 'KIOSK'"-->
          <!--                            tile-->
          <!--                            size="25"-->
          <!--                  >-->
          <!--                    <img-->
          <!--                        :src="require(`../assets/kiosk_${device.isActive ? 'active' : 'noactive'}.png`)"-->
          <!--                        alt="kiosk"-->

          <!--                    >-->
          <!--                  </v-avatar>-->
          <!--                  <v-icon v-else-->
          <!--                          :color="device.isActive ? 'success' : 'pink'"-->
          <!--                          dark-->
          <!--                          v-bind="attrs"-->
          <!--                          v-on="on"-->
          <!--                  >-->
          <!--                    mdi-cellphone-->
          <!--                  </v-icon>-->

          <!--                </template>-->
          <!--                <span>{{ device.isActive ? 'Активний' : 'Вимкнутий' }}</span>-->
          <!--              </v-tooltip>-->
          <!--              <v-tooltip top>-->
          <!--                <template v-slot:activator="{ on, attrs }">-->
          <!--                <span-->
          <!--                    v-bind="attrs"-->
          <!--                    v-on="on">-->
          <!--                  ID: {{ device.deviceId }}-->
          <!--                </span>-->
          <!--                </template>-->
          <!--                <span>{{ device.comment }}</span>-->
          <!--              </v-tooltip>-->
          <!--              <copy-to-clipboard-component hint-text="id" :value="device.deviceId"></copy-to-clipboard-component>-->
          <!--              <v-spacer></v-spacer>-->
          <!--            </v-list-item-title>-->
          <!--          </v-list-item>-->
          <v-list-item-group>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">mdi-hospital-building</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>{{ device.locationName }}</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="success">mdi-account-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span style="margin-left: 10px;">{{ device.cashiers }}</span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="String(device.type).toUpperCase() === 'KIOSK'">
              <v-list-item-icon>
                <v-icon color="info">mdi-cogs</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>FW: <b>{{ device.firmwareVersion ?? 'N/A' }}</b>  |  Conf: <b>{{
                    device.configVersion ?? 'N/A'
                  }}</b> </span>
                <br>
                <span style="padding-top: 7px"><small>IP: <b>{{ device.ipAddress ?? 'N/A' }}</b> <copy-to-clipboard-component
                    v-if="device.ipAddress" hint-text="IP"
                    :value="device.ipAddress"/>  |  MAC: <b>{{ device.macAddress ?? 'N/A' }}</b> <copy-to-clipboard-component
                    v-if="device.macAddress" hint-text="MAC" :value="device.macAddress"/></small></span>
                <span style="padding-top: 7px"><small>RustDeskID: <b>{{ device.rustdeskId ?? 'N/A' }}</b> <copy-to-clipboard-component
                    v-if="device.rustdeskId" hint-text="Rust Desk ID"
                    :value="device.rustdeskId"/>
<!--                                <v-tooltip top>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-btn v-bind="attrs" v-on="on" icon @click="toggleRustDeskService">-->
<!--                    <v-icon size="20" color="primary">mdi-remote-desktop</v-icon>-->
<!--                  </v-btn>-->
<!--                </template>-->
<!--                <span>On/OFF сервісу RustDesk</span>-->
<!--              </v-tooltip>-->
                </small></span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="info">mdi-comment-processing-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span><small>{{ device.comment }}</small></span>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                rounded
                text
                @click="openChangeDeviceDialog(device)"
            >
              Змінити
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
    </v-row>

    <DeviceSetting :device="device" v-model="changeDeviceDialog"></DeviceSetting>


  </v-container>


</template>

<script>


import DeviceSetting from "@/components/DeviceComponents/DeviceSetting.vue";
import apiService from "@/apiServices/apiService";
import {mapState} from "vuex";
import DeviceQuickAction from "@/components/DeviceComponents/DeviceQuickAction.vue";
import ExpansionActionsWrapperComponent from "@/components/FireBaseActions/ExpansionActionsWrapperComponent.vue";
import CopyToClipboardComponent from "@/components/CopyToClipboardComponent.vue";


export default {
  name: "DevicesSettingsPage",
  components: {
    ExpansionActionsWrapperComponent,
    DeviceQuickAction, CopyToClipboardComponent, DeviceSetting
  },
  data: () => ({
    rustDeskSwitch: false,
    isVirtualVisible: false,
    loading: true,
    device: {type: ''},
    fab: {
      isOpen: false,
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: true,
      bottom: true,
      left: false,
      transition: 'slide-y-reverse-transition',
    },
    changeDeviceDialog: false,
    createNewDevice: false,
    createNewVenue: false,
    createNewCashier: false,

    filterTab: null,
    searchQuery: null,
    activeFilter: 'all',
    allDevices: [],
    isErrorGetData: {state: false, msg: 'Дані відсутні 🥺', errMsg: ''},
    tabsFilterMap: {0: 'all', 1: 'MOBILE_CASHIER', 2: 'POSTER', 3: 'KIOSK'}

  }),

  mounted() {
    this.getDevices();
  },
  methods: {

    openSSH(ip) {
      console.log('IP to ssh: ', ip);
      if (ip) {
        window.open(`http://${ip}:8888/?hostname=RK3588&username=kiosk&password=MTc4ODAyODUK`, '_blank');
      } else {
        this.$store.commit('setSnackMessage', {
          message: `IP адреса відсутня`,
          isError: true
        })
      }
    },

    filterChanged(filter) {
      this.activeFilter = filter === 0 ? 'all' : (filter === 3 ? 'KIOSK' : (filter === 2 ? 'POSTER' : 'MOBILE_CASHIER'));
      console.log('[i] Chosen new filter to: ', filter, this.activeFilter);
    },

    async getDevices() {
      try {
        this.isErrorGetData.state = false;
        let devices = await apiService.getDevices();
        console.log('DEVICES ----> ', devices);
        if ('msg' in devices) return false;
        this.loading = false;
        if (devices.success) {
          this.allDevices = devices.devices;
        } else {
          this.isErrorGetData.state = true;
          this.isErrorGetData.errMsg = devices.error;
        }
      } catch (e) {
        this.$store.commit('setSnackMessage', {
          message: `Проблема отримання даних️\n${e}`,
          isError: true
        })
        this.isErrorGetData.state = true;
        this.isErrorGetData.errMsg = e;
        this.loading = false;
      }

    },

    openChangeDeviceDialog(device) {
      this.device = device;
      this.changeDeviceDialog = true;

    },

    searchFilter(data) {
      if (this.searchQuery) {
        console.log('searchQuery before : ', this.searchQuery)
        const searchQuery = this.searchQuery.toLowerCase();
        console.log('searchQuery after : ', searchQuery)
        return data.filter(device => {
          return Object.values(device).some(value => String(value).toLowerCase().includes(searchQuery));
        });
      } else {
        return data
      }

    }
  },
  watch: {
    changeDeviceDialog(val) {
      if (!val) {
        this.getDevices();
      }
    }
  },
  computed: {
    ...mapState(['isNedUpdDeviceList']),


    filteredDevices() {
      const devices = this.isVirtualVisible ? this.allDevices : this.allDevices.filter(device => !device['deviceId'].includes('virtual'))
      if (this.activeFilter === 'all') {
        return this.searchFilter(devices)
      } else {
        return this.searchFilter(devices.filter(device => device.type === this.activeFilter))
      }

    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'changeUpdDeviceList') {
        this.getDevices();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
}
</script>

<style scoped>

.add-btn {
  transition: transform .2s;
}

.add-btn:hover {
  transform: scale(1.5) rotate(180deg);
}

.v-speed-dial {
  position: absolute;
}

.v-btn--floating {
  position: relative;
}

</style>
