<template>
  <v-container fluid>
    <v-snackbars
        :aria-multiline="true"
        :objects.sync="snackMsges"
        elevation="24"
        top
        right
    >
      <template v-slot:action="{ close }">
        <v-btn text @click="close()">Ok</v-btn>
      </template>
    </v-snackbars>
    <v-col v-if="loading" class="text-center loader-class">
      <v-progress-circular
          :rotate="-90"
          :size="200"
          :width="15"
          :value="$store.getters.getProgressVal"
          color="primary"
          :indeterminate="true"
      >
        {{ $store.getters.getProgressVal }}
      </v-progress-circular>
    </v-col>

    <v-row justify="center">
      <v-radio-group
          v-model="selectedSpamBotServer"
          row
      >
        <v-radio
            v-for="(spamBotServerData, index) in spamBotByClubs"
            :key="index"
            :label="spamBotServerData.name"
            :value="spamBotServerData"
            :color="spamBotServerData.color"
        ></v-radio>
      </v-radio-group>
    </v-row>
    <br>
    <v-divider></v-divider>
    <br>
    <v-row justify="center" >
      <v-col
          cols="12"
          md="6"
          sm="12"
      >
        <v-card elevation="24" style="border-radius: 20px">
          <v-card-subtitle>
            <span :class="{'text-length-warning': spamText.length > 767 }">К-сть символів: <strong>{{ spamText ? spamText.length : 0 }}</strong></span>
          </v-card-subtitle>
          <v-card-text>
            <v-textarea
                outlined
                auto-grow
                v-model="spamText"
                name="input-7-4"
                clearable
                clear-icon="mdi-close-circle"
                label="Текст Розсилки"
                prepend-icon="mdi-comment"
                @click:clear="spamText = ''"
            ></v-textarea>
            <v-col
                md="3"
                class="text-left"
            >
              <span>Розсилка на:<strong v-if="selectedSpamBotServer"><br>{{selectedSpamBotServer.name}}</strong></span>
            </v-col>
            <v-col
                md="3"
                offset-md="9"
                class="text-right"
            >
              <span :class="{'text-length-warning': spamText.length > 767 }">К-сть символів: <strong>{{spamText.length}}</strong></span>
            </v-col>

          </v-card-text>
          <v-card-text>
            <v-file-input
                v-if="isImageIncluded"
                label="Картинка"
                show-size
                accept="image/png, image/jpeg, image/jpg"
                prepend-icon="mdi-file-image"
                :rules="rules"
                ref="imgupload"
                @change="onFilePicked"
                @click:clear="imgFile = null"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-switch
                style="margin-left: 3%"
                v-model="isImageIncluded"
                :label="`Картинка`"
            ></v-switch>
            <v-spacer></v-spacer>

            <v-btn
                :disabled="(!((this.spamText && this.spamText.length > 3) && (!this.isImageIncluded || this.imgFile)) ) || loading"
                color="primary"
                large
                class="ma-2 white--text"
                @click="sendSpam"
            >
              {{isOnlyOneDirectSpam ? 'Надіслати' : 'Розіслати'}}
              <v-icon
                  right
                  dark
              >
                mdi-send
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import apiService from "@/apiServices/apiService";
import VSnackbars from "v-snackbars"
import urls from "@/apiServices/urls";

export default {
  name: "SendSpam",
  components: {
    "v-snackbars": VSnackbars
  },
  props: {
    isOnlyOneDirectSpam: Boolean,
    userData: Object,
  },

  data: () => ({
    rules: [
      value => !value || value.size < 819200 || 'Картинка має бути не більше 800 kB!',
    ],
    spamText: '',
    isImageIncluded: false,
    imgFile: null,
    imgUrl: null,
    loading: false,
    snackMsges: [],
    selectedSpamBotServer: null
  }),

  watch: {
    spamText: function (val) {
      if (val.length > 767) {
        this.snackMsges.push({message: `Увага! Кількість символів перевищує 767\nПовідомлення не буде надіслано у Viber!`, color: 'red'})
      }
    }
  },

  methods: {

    onFilePicked(file) {

      if (file) {
        console.log(file)
        console.log(file.name)
        this.imgFile = file;

        // const fr = new FileReader()
        // fr.readAsDataURL(file)
        // fr.addEventListener('load', () => {
        //   this.updateData.name = file.name;
        //   this.updateData.file = fr.result;
        // })
      }

    },


    async sendSpamText() {
      console.log('Bot Service URL: ', this.selectedSpamBotServer)
      console.log('isOnlyOneDirectSpam: ', this.isOnlyOneDirectSpam)
      try {
        // const spamResponse = await apiService.sendOneMsg('telegram', this.spamText, '69895756', this.isImageIncluded ? this.imgUrl : false)
        // const spamResponse = await apiService.sendOneMsg('viber', this.spamText, 'vPZ7Xw1b5bxANha7cO3d2A==', this.isImageIncluded ? this.imgUrl : false)
        let spamResponse;
        if (this.isOnlyOneDirectSpam) {
          spamResponse = await apiService.sendOneMsg(this.userData.botType, this.spamText, this.userData.userId, this.isImageIncluded ? this.imgUrl : false, this.selectedSpamBotServer.server)
        }else{
          spamResponse = await apiService.sendSpam(this.spamText, this.isImageIncluded ? this.imgUrl : false, this.selectedSpamBotServer.server)
        }
        console.log('Spam Response: ', spamResponse)
        if (spamResponse.success) {
          console.log('Send success')
          this.spamText = '';
          this.isImageIncluded = false;
          this.imgFile = null;
          this.imgUrl = null;
          if(this.isOnlyOneDirectSpam){
            alert('✅ Повідомлення надіслано')
          }else{
            alert(`✅ Розсилку запущено\nНадсилається на 👇\nЗагально: ${spamResponse.usersQty.total}\nTelegram: ${spamResponse.usersQty.telegram}\nViber: ${spamResponse.usersQty.viber}\n\nОчікуйте повідомлення про завершення розсилки`)

          }
        } else {
          this.snackMsges.push({message: `Помилка!\n${spamResponse.error instanceof Object ? JSON.stringify(spamResponse.error) : spamResponse.error }`, color: 'red'})
        }
      } catch (spamError) {
        console.log('Send Spam Error: ', spamError)
        this.snackMsges.push({message: `Помилка!\n${spamError}`, color: 'red'})
      } finally {
        this.loading = false;
      }
    },

    async sendTextIfImgUploadFaild(e) {
      this.$store.commit("updateProgressVal", 0);
      console.log('Error upload image: ', e);
      this.loading = false;
      this.success = false;
      const sendTextOnlyConfirm = confirm(`Не вдалося відвантажити картинку❗\n${e}}️\n\nВідправити лише текст?`)
      if (sendTextOnlyConfirm) {
        this.isImageIncluded = false;
        await this.sendSpamText()
      }
    },
    async sendSpam() {
      console.log('Bot Service URL: ', this.selectedSpamBotServer)
      console.log(this.imgFile)
      if(!this.selectedSpamBotServer){
        this.snackMsges.push({message: `Помилка!\nОберіть бота для розсилки`, color: 'red'})
        this.loading = false;
        return false;
      }else{
        if (this.imgFile) {
          if (this.imgFile.size > 819200) {
            this.snackMsges.push({message: `Розмір файлу перевищує 800кБ`, color: 'red'})
            this.imgFile = null;
            return false;
          }
        }
        this.loading = true;
        if (this.isImageIncluded) {
          try {
            const uploadImgResponse = await apiService.uploadImg(this.imgFile)
            console.log('uploadImgResponse: ', uploadImgResponse);
            if (uploadImgResponse.success) {
              this.imgUrl = uploadImgResponse.url;
              await this.sendSpamText()
            } else {
              await this.sendTextIfImgUploadFaild();
            }
            this.loading = false;

          } catch (uploadImgError) {
            console.log('uploadImgError: ', uploadImgError)
            await this.sendTextIfImgUploadFaild(uploadImgError);
          }
        } else {
          await this.sendSpamText()
        }
      }



    }
  },
  computed: {
    spamBotByClubs: function () {
      return urls.spammerServiceUrls
    },
    isDirectToOneSpam: {
      get() {
        return this.isOnlyOneDirectSpam
      },
      set(isOnlyOneDirectSpam) {
        this.$emit('input', isOnlyOneDirectSpam)
      }
    }
  },
  mounted() {
    console.log('SendSpam Mounted: ', this.spamBotByClubs)
  }
}
</script>

<style scoped>

.loader-class {
  position: absolute;
  z-index: 100;
}

.text-length-warning {
  color: red;
  animation: blink 1s infinite;
  animation-fill-mode: both;
}

@keyframes blink {
  0% { opacity: 0 }
  50% { opacity: 1 }
  100% { opacity: 0 }
}

</style>
