const kioskActionButtons = [
    {text: 'Заставка', action: 'update_bg', isLoading: false, color: 'primary'},
    {text: 'G Конфіг', action: 'update_game_config', isLoading: false, color: 'accent'},
    {text: 'Оновити ПЗ', action: 'update_app', isLoading: false, color: 'success'},
    {text: 'P Reload', action: 'page_reload', isLoading: false, color: 'amber'},
    {text: 'UPD RustDesk Id', action: 'update_rustdesk_id', isLoading: false, color: 'primary'},
    {text: 'RustDesk', action: 'rustdesk_service_toggle', isLoading: false, color: 'accent'},
    {text: 'Reboot', action: 'reboot', isLoading: false, color: 'warning'},
    {text: 'Off', action: 'shutdown', isLoading: false, color: 'error'},
    {text: 'Reset', action: 'deactivation', isLoading: false, color: 'deep-orange accent-3'},
]
const androidActionButtons = [
    {text: 'Неактивний', action: 'active_toggle', isLoading: false, color: 'primary'},
]

export {kioskActionButtons, androidActionButtons}
