<template>

  <v-container fluid>
    <v-tabs
        color="deep-purple accent-4"

    >
      <v-tab v-for="(botServiceDataN, index) in spamBotByClubs"
             :key="index">{{botServiceDataN.name}}</v-tab>

      <v-tab-item
          v-for="(botServiceData, index) in spamBotByClubs"
          :key="index"
      >
        <v-container fluid>
          <spam-history v-if="isHistoryDisplayed" :spam-bot-data="botServiceData"></spam-history>
          <spam-users-list v-else :spam-bot-data="botServiceData"></spam-users-list>

        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-container>


</template>

<script>
import urls from "@/apiServices/urls";
import SpamUsersList from "@/components/Spam/SpamUsersList.vue";
import SpamHistory from "@/components/Spam/SpamHistory.vue";


export default {
  name: "SpamUsersListAll",
  components: {SpamHistory, SpamUsersList},
  props: {
    isHistoryDisplayed: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
  }),
  computed: {
    spamBotByClubs: function () {
      return urls.spammerServiceUrls
    },
  },
}
</script>

<style scoped>

</style>
