import { render, staticRenderFns } from "./MainChartsComponent.vue?vue&type=template&id=39b6cac4&scoped=true&"
import script from "./MainChartsComponent.vue?vue&type=script&lang=js&"
export * from "./MainChartsComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39b6cac4",
  null
  
)

export default component.exports